import {
  PhoneOutlined,
  RobotOutlined,
  BarChartOutlined,
  DotChartOutlined,
} from '@ant-design/icons';

export default {
  route: {
    path: '/',
    routes: [
      {
        path: '/database',
        name: 'Product',
        icon: <BarChartOutlined />,
        // component: <TableDemo />
      },
      {
        path: '/prediction',
        name: 'Insights',
        icon: <DotChartOutlined />,
        // component: <TableDemo />
      },
      {
        path: '/aichat',
        name: 'About',
        icon: <RobotOutlined />
      }
    ]
    /*
    routes: [
      {
        path: '/database',
        name: '通用数据查询',
        icon: <BarChartOutlined />,
        // component: <TableDemo />
      },
      {
        path: '/prediction',
        name: '数据趋势预测',
        icon: <DotChartOutlined />,
        // component: <TableDemo />
      },
      {
        path: '/aichat',
        name: 'AI 智能查询',
        icon: <RobotOutlined />
      },
      {
        path: '/support',
        name: '支持与服务',
        icon: <PhoneOutlined />
        // component: <FormDemo />
      }
    ]
      */
  }
}