import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './layout';
import FrontPage from './pages/frontpage';
import Portal from './pages/portal';
import Database from './pages/database';
import AIChat from './pages/aichat';
import Support from './pages/support';

import APIDemo from './pages/api';
import Prediction from './pages/prediction';


const router = createBrowserRouter([
  {
    path: "/hello",
    element: <div>Hello world!</div>,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Portal />
      },
      {
        path: 'home',
        element: <Portal />
      },
      {
        path: 'database',
        element: <Database />
      },
      {
        path: 'prediction',
        element: <Prediction />
      },
      {
        path: 'aichat',
        element: <AIChat />
      },
      {
        path: 'support',
        element: <Support />
      },
      {
        path: 'api-demo',
        element: <APIDemo />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
