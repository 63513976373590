import { EllipsisOutlined, PlusOutlined,CreditCardOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Typography } from 'antd';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import { Dropdown, Space, Tag , Divider,Carousel,Col, Row,Radio,Input,RadioChangeEvent } from 'antd';
import { useRef,React,useState } from 'react';
import request from 'umi-request';
export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};
const contentStyle = {
  margin: 0,
  height: '240px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  borderRaduis: '10px',
  position: 'relative'
  //background: '#1d3d6c',
};

const TitleStyle = {

  position: 'absolute',
  left: "75px",
  top : "65px",
  color : "white",
  fontSize : "30px",
};

const cardStyle: React.CSSProperties = {
  width: "100%",
  borderRaduis: '10px',
  margin: "50px 0"
};

const imgStyle: React.CSSProperties = {
  display: 'block',
  width: "300px",
  borderRaduis: '10px',
  height: "300px"
};


const { Title, Paragraph, Text, Link } = Typography;
const { Meta } = Card;

export default () => {
  const actionRef = useRef();
  const [value, setValue] = useState(1);
  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  return (

    <>
    <Row>
    <Col span={18} push={6}>
      <Flex gap="large" align="start" vertical>
        <Radio.Group defaultValue="report" buttonStyle="solid">
          <Radio.Button value="report">Report</Radio.Button>
          <Radio.Button value="data">Data</Radio.Button>
          <Radio.Button value="release">Release</Radio.Button>
        </Radio.Group>
        <Space size="large">
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_4_9e7122b8f0.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_2_8b3e47abee.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_3_223dccb56a.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_1_f51c524faa.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
        </Space>
        <Space size="large">
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_4_9e7122b8f0.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_2_8b3e47abee.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_3_223dccb56a.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_1_f51c524faa.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
        </Space>
        <Space size="large">
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_4_9e7122b8f0.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_2_8b3e47abee.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_3_223dccb56a.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src="https://cms.nyotaresearch.com/uploads/ivy_1_f51c524faa.png" />}
          >
            <Meta title="Tailand Electricity Report" description="27 July 2024" />
          </Card>
      </Space>
    </Flex>
    </Col>
    <Col span={6} pull={18}>
      <p>
        &nbsp;
      </p>
    <Space size="large" align="start">
      <CreditCardOutlined />

      <Radio.Group onChange={onChange} value={value}>
          
        <Flex gap="middle" align="start" vertical>  
            <Radio value={1}>Electricity</Radio>
            <Radio value={2}>Water</Radio>
            <Radio value={3}>Power</Radio>
        </Flex>
        </Radio.Group>
      </Space>
    </Col>
  </Row>
   </>
    );
};