import { Card, Spin, Typography, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';

import request from '../services/request';

const { Paragraph, Text } = Typography;


export default () => {

  const [reports, setReports] = useState([])

  useEffect(() => {
    request('consulting-reports').then(res => {
      const { data } = res
      setReports((data || []).map(report => ({
        ...report?.attributes,
        id: report.id,
      })))
    })
  }, [])

  return (<Spin spinning={!reports.length}>
    { reports.map(report => (<>
      <Card title={report.title} extra={<Text>
        { report.publish_at }
      </Text>}>
        <Paragraph>
          { report.abstract }
          <Button type="link" href={report.link}>
            {report.btn_text}
            <RightOutlined style={{ marginLeft: 0 }} />
          </Button>
        </Paragraph>
      </Card>
    </> )) }
    </Spin>);
};