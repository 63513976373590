import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import { Button, Dropdown, Space, Tag , Divider, Typography,Carousel } from 'antd';
import { useRef } from 'react';
import request from 'umi-request';
export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};
const contentStyle = {
  margin: 0,
  height: '240px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  borderRaduis: '10px'
  //background: '#1d3d6c',
};




const { Title, Paragraph, Text, Link } = Typography;


export default () => {
  const actionRef = useRef();
  return (

    <Typography>
       


        
        <Carousel autoplay>
          <div style={contentStyle}>
          <h3 style={contentStyle}><img src={require('../assets/support.png')}  alt="logo" /></h3>
          </div>
        </Carousel>
        <Divider />
        <Paragraph>
          Omniscient Ocean数据库系统提供强大的在线技术支持。我们拥有经验丰富的技术和业务专家随时准备帮助您，解决使用过程中遇到的任何问题。
          我们还提供一系列详细的使用指南和在线教程，帮助用户更好地理解和使用我们的系统。这些资源涵盖了从简单的数据查询，到复杂的AI智能查询的各种操作。我们还定期更新这些资料，以反映我们系统的最新功能和改进。
        </Paragraph>
        <Paragraph>
          <Text strong>我们的技术支持团队可以通过电话、电子邮件或在线聊天与您联系，以确保您的问题得到及时解决。</Text>
        </Paragraph>
        <Paragraph>
          <ul>
            <li>电话：+86 123456789</li>
            <li>电子邮件：support@OmniscientOcean.com</li>
            <li>微信客服： OmniscientOceanSupport</li>
          </ul>
        </Paragraph>
      </Typography>
    );
};