import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Typography } from 'antd';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import { Dropdown, Space, Tag , Divider,Carousel } from 'antd';
import { useRef,React } from 'react';
import request from 'umi-request';
export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};
const contentStyle = {
  margin: 0,
  height: '240px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  borderRaduis: '10px',
  position: 'relative'
  //background: '#1d3d6c',
};

const TitleStyle = {

  position: 'absolute',
  left: "75px",
  top : "65px",
  color : "white",
  fontSize : "30px",
};

const cardStyle: React.CSSProperties = {
  width: "100%",
  borderRaduis: '10px',
  margin: "50px 0"
};

const imgStyle: React.CSSProperties = {
  display: 'block',
  width: "300px",
  borderRaduis: '10px',
  height: "300px"
};


const { Title, Paragraph, Text, Link } = Typography;


export default () => {
  const actionRef = useRef();
  return (

    <><Carousel autoplay>
      <div style={contentStyle}>
        <div>
          <div>
            {/*
            <img style={{ height: "240px" }} src={require('../assets/carusel-1.png')} alt="logo" />
             */}
          </div>
          <div style={TitleStyle}>
            <h3>Nyota 数据库</h3>
          </div>
        </div>
      </div>
    </Carousel>
      <Card hoverable style={cardStyle} bodyStyle={{ padding: 0, overflow: 'hidden' }}>
        <Flex justify="space-between">
          <img
            alt="avatar"
            src={require('../assets/data_1.png')}
            style={imgStyle}
          />
          <Flex vertical align="flex-end" justify="space-between" style={{ padding: 32 ,background: 'radial-gradient(circle at bottom right, #deeeff, white)'}}>
            <Typography.Title level={3}>
            通用数据查询
            </Typography.Title>
            <Paragraph>
            通用数据查询为用户提供直接访问全球各地行业数据的能力。只需几次点击，你就可以在我们庞大的数据库中找到所需的信息。这个功能强调简便易用，并能通过各种筛选条件，如国家、行业或时间段，满足用户查询特定数据的需求。无论您是正在分析市场趋势，还是想要了解某个特定行业的历史表现，通用数据查询功能都可以帮助您迅速获取所需的信息。
             </Paragraph>  

            <Button type="primary" href="database" target="_blank">
            立即使用
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card hoverable style={cardStyle} bodyStyle={{ padding: 0, overflow: 'hidden' }}>
        <Flex justify="space-between">
          <img
            alt="avatar"
            src={require('../assets/predict_1.png')}
            style={imgStyle}
          />
          <Flex vertical align="flex-end" justify="space-between" style={{ padding: 32 ,background: 'radial-gradient(circle at bottom right, #deeeff, white)'}}>
            <Typography.Title level={3}>
            数据趋势预测
            </Typography.Title>
            <Paragraph>
            数据预测服务基于历史数据和专家分析，运用先进的算法和模型，对未来的行业和市场情况进行预测。我们的预测结果准确可靠，能够为您提供有价值的决策支持。
            无论您是企业管理者、投资者还是决策者，我们的数据预测服务都能够帮助您更好地了解市场动态，把握行业趋势，制定更加科学合理的战略规划。我们的服务涵盖了多个领域，包括能源、金融、制造业、医疗等，能够满足不同客户的需求。               </Paragraph>  
            <Button type="primary" href="prediction" target="_blank">
            立即使用
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card hoverable style={cardStyle} bodyStyle={{ padding: 0, overflow: 'hidden' }}>
        <Flex justify="space-between">
          <img
            alt="avatar"
            src={require('../assets/data_2.png')}
            style={imgStyle}
          />
          <Flex vertical align="flex-end" justify="space-between" style={{ padding: 32 ,background: 'radial-gradient(circle at bottom right, #91caff, white)'}}>
            <Typography.Title level={3}>
            AI 智能查询
            </Typography.Title>
            <Paragraph>
              利用最新的人工智能大模型技术（如ChatGPT），支持在短时间内处理大量数据，并提供精细的数据分析结果。只需输入查询要求，AI智能查询功能就能自动分析数据并生成报告，可以为用户节省了大量的分析和处理时间。此外，它还有学习和适应用户需求的能力，使其在每次使用后都能提供更准确和个性化的结果。
            </Paragraph>
            <Button type="primary" href="aichat" target="_blank">
              立即使用
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card hoverable style={cardStyle} bodyStyle={{ padding: 0, overflow: 'hidden' }}>
        <Flex justify="space-between">
          <img
            alt="avatar"
            src={require('../assets/data_3.png')}
            style={imgStyle}
          />
          <Flex vertical align="flex-end" justify="space-between" style={{ padding: 32 ,background: 'radial-gradient(circle at bottom right, #ddd, white)'}}>
            <Typography.Title level={3}>
            支持与服务            
            </Typography.Title>
            <Paragraph>
              我们拥有经验丰富的技术和业务专家，提供便捷的在线技术支持，随时准备帮助您解决使用过程中遇到的任何问题。同时，提供一系列详细的使用指南和在线教程，帮助用户更好地理解和使用。
            </Paragraph>
            <Paragraph>
              <ul>
                <li>电话：+86 123456789</li>
                <li>电子邮件：support@OmniscientOcean.com</li>
                <li>微信客服： OmniscientOceanSupport</li>
              </ul>
            </Paragraph>
          </Flex>
        </Flex>
      </Card>

    <Typography>
    <Paragraph align="center">
    Copyright © Omniscient Ocean 2023. All rights reserved.
    </Paragraph>
    </Typography></>
    );
};