import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown,QueryFilter,ProFormSelect,ProFormDateYearRangePicker ,ProFormRadio,ProForm} from '@ant-design/pro-components';
import { Button, Dropdown, Space, Tag,Card,Table,Spin,Alert ,Carousel} from 'antd';
import {
  AreaChartOutlined
} from '@ant-design/icons';
import { useRef,useState } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import request from 'umi-request';
export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};


const contentStyle = {
  margin: 0,
  height: '240px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  borderRaduis: '10px'
  //background: '#1d3d6c',
};

const tableColumns = [
  {
    title: '国家',
    dataIndex: 'country_name',
    key: 'country_name',
  },
  {
    title: '年份',
    dataIndex: 'period_year',
    key: 'period_year',
  },
  {
    title: '数据项',
    dataIndex: 'data_key_name',
    key: 'data_key_name',
  }
  ,
  {
    title: '数据值',
    dataIndex: 'data_value',
    key: 'data_value',
  },
  {
    title: '单位',
    dataIndex: 'data_unit',
    key: 'data_unit',
  },
  {
    title: '数据来源',
    dataIndex: 'source',
    key: 'source',
  }
];


const dataKeys = {
  elec_gen_total: '发电量总',
  elec_gen_thermal: '发电量-火电',
  elec_gen_hydro: '发电量-水电',
  elec_gen_wind: '发电量-风电',
  elec_demand: '电力总需求量',
  elec_install: '电力装机量',
  elec_import: '电力进口量',
  elec_export: '电力出口量',
}

const countryList ={
  Turkey: '土耳其',
  Italy: '意大利',
  Oman: '阿曼',
  Albania: '阿尔巴尼亚',
  Amibia: '纳米比亚',
}

const chartTypeEnum = {
  bar: '柱状图',
  line: '折线图'
}


const EMPTY_OPTION = {};

function getDataLeyLabel(){


}

function getChartsOptions(data) {
  return {
    title: {
        text: '查询结果：【'+countryList[data.country] + '】' + '【' + data.data_period_start + ' - ' + data.data_period_end + '】' + dataKeys[data.data_key] 
    },
    tooltip: {
        trigger: 'axis'
    },
    toolbox: {
        show:true,
        feature:{
          dataView: {
            show:true,
            readOnly: true,
            title:'查看数据'
          },
          saveAsImage: {
            show:true,
            type:'png',
            title:'保存为图片'
          }
        }
    },
    legend: {
        data: [
          dataKeys[data.data_key]
        ]
    },
    grid: {
        top: 60,
        left: 60,
        right: 60,
        bottom: 30
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    /*
    visualMap: {
        show: false,
        min: 0,
        max: 1000,
        color: ['#BE002F', '#F20C00', '#F00056', '#FF2D51', '#FF2121', '#FF4C00', '#FF7500',
            '#FF8936', '#FFA400', '#F0C239', '#FFF143', '#FAFF72', '#C9DD22', '#AFDD22',
            '#9ED900', '#00E500', '#0EB83A', '#0AA344', '#0C8918', '#057748', '#177CB0']
    },
    */
    xAxis: [
        {
            type: 'category',
            boundaryGap: true,

            // data: (function () {
            //     let now = new Date();
            //     let res = [];
            //     let len = 20;
            //     while (len--) {
            //         res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''));
            //         now = new Date(now - 2000);
            //     }
            //     return res;
            // })()

        }
    ],
    yAxis: [
        {
            type: 'value',
            scale: true,
            name: 'GWh',
            min: 0,
            boundaryGap: [0.2, 0.2]
        }
    ],
    dataset: {
      source: data.dataset
    },
    series: [
        {
            name: dataKeys[data.data_key],
            type: data.type?data.type:'bar',
            xAxisIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    barBorderRadius: 4,
                }
            },
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return idx * 10;
            },
            animationDelayUpdate: function (idx) {
                return idx * 10;
            },
            // data: (function () {
            //     let res = [];
            //     let len = 50;
            //     while (len--) {
            //         res.push(Math.round(Math.random() * 1000));
            //     }
            //     return res;
            // })()
        }
    ]
  };
}


export default () => {
  const actionRef = useRef();
  const [chartData, setChartData] = useState({});
  const [chartType, setChartType] = useState('bar');
  const [isVisible, setIsVisible] = useState(false);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [loading,setLoading] = useState(false);
  const [query,setQuery] = useState('');

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
      >
        <Carousel autoplay>
        <div style={contentStyle}>
        <h3 style={contentStyle}><img src={require('../assets/prediction.png')}  alt="logo" /></h3>
        </div>
      </Carousel>
      <Card>
        <QueryFilter
          defaultCollapsed={false}
          onFinish={async (values) => {


            const params = {
              country: values.country,
              data_key: values.data_key,
              data_period_start: values.data_period[0].substring(0,4),
              data_period_end: values.data_period[1].substring(0,4)
              // 更多的参数...
            };

            setQuery('【'+countryList[params.country] + '】' + '【' + params.data_period_start + ' - ' + params.data_period_end + '】' + dataKeys[params.data_key] )

            setLoading(true);

            await waitTime(1500);

            const response = await axios.get('https://api.rubyai.cn/ocean_data_query', { params });
 
            setLoading(false);

            setIsVisible(true);
            let resultArr = response.data.map(obj => {
              return {
                  'period_year': obj.period_year,
                  'data_value': obj.data_value
              };
            });


            const tableData = response.data.map(item => {
              return {
                ...item,
                country_name: countryList[item.country],
                data_key_name: dataKeys[item.data_key]
              };
            });
        
            setTableDataSource(tableData);

            console.log(JSON.stringify(response.data));



            setChartData(getChartsOptions(
              {
                dataset:resultArr,
                type: chartType,
                country: values.country,
                data_key: values.data_key,
                data_period: values.data_period,
                data_period_start: values.data_period[0].substring(0,4),
                data_period_end: values.data_period[1].substring(0,4),
              }
            ));

            // if (response.data.length === 0) {
            //   setIsVisible(false);
            // }
        }}
        >
       <ProFormSelect
            name="country"
            label="国别"
            valueEnum={countryList}
            placeholder="请选预测国别"
            style={{maxWidth: 300, width: '300' }}
            rules={[{ required: true, message: '请选择预测国别。' }]} />
          <ProFormSelect
            name="data_key"
            label="预测数据项"
            valueEnum={dataKeys}
            placeholder="请选择预测数据项"
            style={{maxWidth: 300, width: '300' }}
            rules={[{ required: true, message: '请选择预测数据项。' }]} />
            <ProFormDateYearRangePicker name="data_period" label="时间段" minDate="2024-01-01" maxDate="2030-01-01" rules={[{ required: true, message: '请选择起止时间段。' }]} />
            <ProFormRadio.Group
            style={{
                margin: 16,
              }}
              radioType="button"
              name="chartType"
              label="图形类型"
              fieldProps={{
                value: chartType,
                onChange: (e) => setChartType(e.target.value),
              }}
              options={[
                {
                  label: '柱状图',
                  value: 'bar',
                },
                {
                  label: '折线图',
                  value: 'line',
                }
              ]}
            />
        </QueryFilter>
      </Card>

      {loading && (<Card><Spin tip="正在处理您的预测查询 ...">
          <Alert
            message="您预测的数据是"
            description={query}
            type="info"
          />
        </Spin></Card>)}

      {isVisible && (
        <Card>
          <ReactECharts option={chartData} style={{ height: 400 }}/>
        </Card>
      )}
      {isVisible && (
        <Card>
          <Table dataSource={tableDataSource} columns={tableColumns}/>
        </Card>
      )}

    </Space>
  );
};