import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown,QueryFilter,ProFormSelect,ProFormDateYearRangePicker,ProFormText,ProForm } from '@ant-design/pro-components';
import { Button, Dropdown, Space, Tag,Card ,Input, Divider,Spin,Alert,Carousel,Tabs} from 'antd';
import {
  RobotOutlined,MessageOutlined
} from '@ant-design/icons';
import { useRef,useState} from 'react';
import axios from 'axios';
import Markdown from 'react-markdown'
import { set } from 'lodash';

const { TabPane } = Tabs;

const contentStyle = {
  margin: 0,
  height: '240px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  borderRaduis: '10px'
  //background: '#1d3d6c',
};

const industryList = {
  ELEC: '新能源电力'
}

/*
const countryList ={
  JP : "日本",
  HU : "匈牙利",
  TR : "土耳其",
  IT : "意大利",
  AL : "阿尔巴尼亚",
  NA : "纳米比亚",
  ES : "西拔牙"
}
*/

const countryList ={
  report_001 : "日本",
  report_002 : "匈牙利",
  report_003 : "土耳其",
  report_004 : "意大利",
  report_005 : "阿尔巴尼亚",
  report_006 : "纳米比亚",
  report_007 : "西拔牙"
}

const reportList ={
  report_001: '日本电力行业研究报告',
  report_002: '匈牙利新能源电力行业研究报告',
  report_003: '土耳其新能源电力行业研究',
  report_004: '意大利新能源电力行业分析报告',
  report_005: '阿尔巴尼亚新能源电力行业分析报告',
  report_006: '纳米比亚新能源电力行业分析报告',
  report_007: '西班牙电力市场研究报告'
}

export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};

export const AnswerList = (props) => {
  const { data } = props;
  return (
    data.map((item, index) => (
        <Card>
          <h3><MessageOutlined />&nbsp; {item.prompt}</h3>
          <Markdown>{item.message}</Markdown>
        </Card>
    ))
  )
}; 

export default () => {
    const actionRef = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const [loading,setLoading] = useState(false);
    const [query,setQuery] = useState('');
    const [markdownText, setMarkdownText] = useState('');
    const [answerList, setAnswerList] = useState([]);


    return (
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
        >
        <Carousel autoplay>
          <div style={contentStyle}>
          <h3 style={contentStyle}><img src={require('../assets/aichat.png')}  alt="logo" /></h3>
          </div>
        </Carousel>


        <Tabs defaultActiveKey="1">
          <TabPane tab="国家/行业知识库" key="1">

          <Card title="通过指定的国别和行业领域专业知识库，解答您的问题">
            <ProForm
              defaultCollapsed={false}
              layout="vertical"
              onFinish={async (values) => {
                setLoading(true);

                setQuery(values.prompt)
                await waitTime(1000);

                const params = {
                  collection : values.country,
                  country : values.country,
                  country_name : countryList[values.country],
                  industry : values.industry,
                  industry_name: industryList[values.industry],
                  tab: values.tab,
                  prompt: values.prompt
                };
                
                const response = await axios.get('https://api.rubyai.cn/qdrant/', { params });

                setLoading(false);                
                setIsVisible(true);
                
                setMarkdownText(response.data.message);

                console.log(response.data);

                setAnswerList([response.data,...answerList]);

              }}
            >
              <ProForm.Group>

                <ProFormSelect
                  name="country"
                  label="国别"
                  width="sm"
                  valueEnum={countryList}
                  placeholder="请选择国别"
                  style={{ maxWidth: 522, width: '522' }}
                  rules={[{ required: true, message: '请选择国别。' }]} />
                
                <ProFormSelect
                  name="industry"
                  label="行业"
                  width="sm"
                  valueEnum={industryList}
                  placeholder="请选择行业"
                  style={{ maxWidth: 522, width: '522' }}
                  rules={[{ required: true, message: '请选择行业。' }]} />
                </ProForm.Group>
                <ProFormText
                  name="prompt"
                  label="问题"
                  width=""
                  placeholder="请输入问题"
                  rules={[{ required: true, message: '请输入问题。' }]} 
                />
                <Input type="hidden" name="tab" value="0"/>
            </ProForm>
          </Card>
          </TabPane>
          <TabPane tab="研究报告智能问答" key="2">
            <Card title="根据您购买的行业研究报告，解答您的问题">
              <ProForm
                defaultCollapsed={false}
                layout="vertical"
                onFinish={async (values) => {
                  
                  setLoading(true);

                  setQuery(values.prompt)
                  await waitTime(1000);

                  const params = {
                    collection: values.report,
                    report: values.report,
                    report_name: reportList[values.report],
                    tab: values.tab,
                    prompt: values.prompt
                  };
                  
                  const response = await axios.get('https://api.rubyai.cn/qdrant/', { params });

                  setLoading(false);                
                  setIsVisible(true);
                  
                  setMarkdownText(response.data.message);

                  console.log(response.data);

                  setAnswerList([response.data,...answerList]);
                  
                }}
              >
                <ProForm.Group>

                  

                  </ProForm.Group>
                  <ProFormSelect
                    name="report"
                    label="研究报告"
                    width="xl"
                    valueEnum={reportList}
                    placeholder="请选择研究报告"
                    style={{ maxWidth: 833, width: '833' }}
                    rules={[{ required: true, message: '请选择研究报告。' }]} />
                  <ProFormText
                    name="prompt"
                    label="问题"
                    width=""
                    placeholder="请输入问题"
                    rules={[{ required: true, message: '请输入问题。' }]} 
                  />
                  <Input type="hidden" name="tab" value="1"/>
              </ProForm>
            </Card>
          </TabPane>
        </Tabs>
        
        
        {loading && (<Card><Spin tip="正在通过 AI 处理您的查询 ...">
          <Alert
            message="您查询的问题是"
            description={query}
            type="info"
          />
        </Spin></Card>)}

        <AnswerList
          data={answerList}
        />
      </Space>
    );
  };